import React from "react";

import { MainContainer, Paragraph } from "./styles";

const AboutMe = () => (
  <MainContainer>
    <Paragraph
      size="22px"
      margin="10px 0"
      spacing="-1px"
      weight="bolder"
      align="center"
    >
      Front end developer based in Mexico City.
    </Paragraph>
    <Paragraph
      align="justify"
      margin="10px 0 0 0"
      font={`"Space Mono", monospace`}
    >
      As a tech enthusiast with a love for reading and gaming, I am committed to leveraging my front-end development skills to create engaging and intuitive user interfaces. With a keen eye for detail and a user-centric approach, I strive to deliver high-quality results for every project.
    </Paragraph>
    <Paragraph align="justify" font={`"Space Mono", monospace`}>
    In my free time, I can be found exploring the pages of horror, science fiction, and fantasy novels, or immersing myself in the digital worlds of video games.
    </Paragraph>
    <Paragraph
      align="center"
      color="#00bfa8"
      weight="bolder"
      font-size="18px"
      margin="10px 0 0 0"
    >
      Avaible for freelance work.
    </Paragraph>
    <Paragraph align="center" font-size="16px" spacing="-1px" margin="15px 0">
      tzupratts@proton.me
    </Paragraph>
  </MainContainer>
);

export default AboutMe;
